<template>
<div class="review-get-start mt-8">
    <div class="vx-row" v-if="!addEditLocation">
        <div class="vx-col w-full xl:w-1/4 lg:w-1/2 md:w-1/2 mb-8 lg-cus-6" v-for="(location, index) in locations" :key="index">
            <vx-card class="review-card h-full">
                <h2>{{ location.LocationName }}</h2>
                <div class="review-action">
                    <vs-button radius color="success" type="filled" icon-pack="feather" icon="icon-edit" @click="editLocation(location)"></vs-button>
                    <vs-button radius color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" @click="
                deletelocation(location.LocationId, location.LocationName, 'L')
              "></vs-button>
                </div>
                <ul class="address">
                    <li>
                        <feather-icon icon="MapPinIcon" class="text-gray"></feather-icon>
                        <div>
                            <p>{{ location.BusinessName }}</p>
                            <p>{{ location.Address1 }}</p>
                            <p>{{ location.Address2 }}</p>
                            <p>
                                {{ location.City }}, {{ location.State }} {{ location.Zip }}
                            </p>
                        </div>
                    </li>
                    <li>
                        <feather-icon icon="SmartphoneIcon" class="text-gray"></feather-icon>
                        <div>
                            <p>{{ location.Phone }}</p>
                        </div>
                    </li>
                </ul>
            </vx-card>
        </div>
        <div class="vx-col w-full xl:w-1/4 lg:w-1/2 md:w-1/2 mb-8 lg-cus-6">
            <vx-card class="review-card h-full null-card">
                <a>
                    <img src="../../assets/images/location.png" />
                    <vs-button radius type="filled" class="mt-4 small-btn bg-actionbutton" @click="addLocation()" :disabled="isAddLocation">{{ LabelConstant.buttonLabelAddLocation }}</vs-button>
                </a>
            </vx-card>
        </div>
    </div>
    <vx-card v-show="addEditLocation == true">
        <vs-progress :percent="progressStep" color="secondary" class="mb-8"></vs-progress>
        <div class="manage-steps">
            <div class="step-form" v-show="step == 1">
                <form data-vv-scope="basicInformation">
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <div class="card-title">
                                <h2>Tell us about your company</h2>
                            </div>
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col md:w-1/2 mb-6 w-full">
                            <vs-input class="w-full" label="Business Name" v-model="businessName" name="businessName" v-validate="'required'" />
                            <span class="text-danger text-sm">{{
                  errors.first("basicInformation.businessName")
                }}</span>
                        </div>
                        <div class="vx-col md:w-1/2 mb-6 w-full">
                            <vs-input class="w-full" label="Friendly Name for the Location" v-model="friendlyNameForLocation" name="friendlyNameForLocation" v-validate="'required'" />
                            <span class="text-danger text-sm">{{
                  errors.first("basicInformation.friendlyNameForLocation")
                }}</span>
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col md:w-1/2 mb-6 w-full">
                            <span class="vs-input--label w-full float-left">Address 1</span>
                            <vs-textarea v-model="address1" class="mb-0" v-validate="'required'" name="address1" />
                            <!-- <br /> -->
                            <span class="text-danger text-sm">{{
                  errors.first("basicInformation.address1")
                }}</span>
                        </div>
                        <div class="vx-col md:w-1/2 mb-6 w-full">
                            <span class="vs-input--label w-full float-left">Address 2</span>
                            <vs-textarea v-model="address2" class="mb-0" />
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col md:w-1/2 mb-6 w-full">
                            <span class="vs-input--label">Country</span>
                            <v-select v-model="country" :options="countryoptions" :reduce="countryoptions => countryoptions.Key" label="Value" :clearable="false" />
                        </div>
                        <div class="vx-col md:w-1/2 mb-6 w-full">
                            <vs-input class="w-full" label="State" v-model="stateName" name="stateName" v-validate="'required'" />
                            <span class="text-danger text-sm">{{
                  errors.first("basicInformation.stateName")
                }}</span>
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col md:w-1/2 mb-6 w-full">
                            <vs-input class="w-full" label="City" v-model="cityName" name="cityName" v-validate="'required'" />
                            <span class="text-danger text-sm">{{
                  errors.first("basicInformation.cityName")
                }}</span>
                        </div>
                        <div class="vx-col md:w-1/2 mb-6 w-full">
                            <vs-input class="w-full" label="Zip" v-model="zipCode" name="zipCode" v-validate="'required'" />
                            <span class="text-danger text-sm">{{
                  errors.first("basicInformation.zipCode")
                }}</span>
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col md:w-1/2 mb-6 w-full">
                            <vs-input class="w-full" label="Phone" v-model="phone" v-validate="'required'" name="phone" />
                            <span class="text-danger text-sm">{{
                  errors.first("basicInformation.phone")
                }}</span>
                        </div>
                        <div class="vx-col md:w-1/2 mb-6 w-full">
                            <span class="vs-input--label">Category</span>
                            <v-select v-model="category" :options="categoryoptions" :clearable="false" />
                        </div>
                    </div>
                </form>
            </div>
            <!-- Connections START -->
            <div class="connect-account" v-show="step == 2">
                <div class="card-title">
                    <h2>Tell us about your company</h2>
                </div>

                <!-- Your Connected Account -->
                <div class="your-connected-account">
                    <div class="card-title">
                        <h2>Your connected accounts</h2>
                    </div>
                    <div class="add-connection">
                        <vs-button title="Add GMB Profile" type="filled" icon-pack="feather" icon="icon-plus" class="small-btn float-left sm:w-auto w-full bg-actionbutton" @click="addGMBConnectedAccount()">{{ LabelConstant.buttonLabelAddGMBProfile }}</vs-button>
                        <vs-button title="Add Facebook Profile" type="filled" icon-pack="feather" icon="icon-plus" class="bg-actionbutton small-btn float-left ml-0 sm:ml-4 sm:mt-0 mt-3 sm:w-auto w-full" @click="addFacebookConnectedAccount()">{{ LabelConstant.buttonLabelAddFacebookProfile }}</vs-button>
                    </div>
                    <vs-table ref="table" :data="connectedAccounts">
                        <template slot="thead">
                            <vs-th sort-key="ConnectedAccountName">Account</vs-th>
                            <vs-th sort-key="ConnectedAccountType">Type</vs-th>
                            <vs-th>Action</vs-th>
                        </template>
                        <template slot-scope="{ data }">
                            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td>
                                    <p>{{ tr.ConnectedAccountName }}</p>
                                </vs-td>
                                <vs-td>
                                    <p>{{ tr.ConnectedAccountType }}</p>
                                </vs-td>
                                <vs-td class="table-action-btn">
                                    <vs-button type="filled" icon-pack="feather" icon="icon-refresh-ccw" class="mr-2 float-left" color="primary" title="Reauth" @click="
                        (reauthAccountId = tr.Id),
                          (reauthAccountType = tr.ConnectedAccountType),
                          reauthConnectedAccount()
                      "></vs-button>
                                    <vs-button color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="float-left" title="Delete" @click="
                        (deleteConnectedAccountPopupActive = true),
                          (deletableConnectedAccount = tr.ConnectedAccountName),
                          (deletableConnectedAccountId = tr.Id)
                      "></vs-button>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
                <div class="connection-table">
                    
                    <div class="common-table">      
                        <!--Table Select Row-->
                        <div class="table-row-select lead-select">
                            <vs-select label="Show Entries" v-model="perPage" class="show-select select-connection" autocomplete>
                                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in recordList" />
                            </vs-select>
                        </div>
                        <vs-table ref="tableMapProfileLocation" :data="accountMapsForLocation" max-items="8" pagination>
                            <template slot="thead">
                                <vs-th>Source</vs-th>
                                <vs-th>Profile\Page</vs-th>
                                <vs-th>Status</vs-th>
                                <vs-th>Action</vs-th>
                            </template>
                            <template slot-scope="{ data }">
                                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                    <vs-td>
                                        <div class="source-list" v-if="tr.ConnectionType == 'Facebook'">
                                            <img src="../../assets/images/facebook-icon.png" />
                                            <span>{{ tr.ConnectionType }}</span>
                                        </div>
                                        <div class="source-list" v-if="tr.ConnectionType == 'GMB'">
                                            <img src="../../assets/images/google-map.png" />
                                            <span>{{ tr.ConnectionType }}</span>
                                        </div>
                                    </vs-td>
                                    <vs-td :data="tr.RemoteSystemAccountName">
                                        <span>{{tr.RemoteSystemAccountName}}</span>
                                    </vs-td>
                                    <vs-td :data="tr.IsConnected">
                                        <span v-if="tr.IsConnected">Connected</span>
                                        <span v-else>Not Connected</span>
                                    </vs-td>
                                    <vs-td>
                                        <div class="table-action">
                                            <vs-button title="Update" color="primary" type="filled" icon-pack="feather" icon="icon-refresh-ccw" class="mr-2 float-left" @click="
                                (conntectedAccountType = tr.ConnectionType),
                                prepareProfileList()
                            "></vs-button>
                                            <vs-button title="Delete" color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="float-left" v-if="tr.IsConnected" @click="
                            (deleteLocationConnectedAccountMapPopupActive = true),
                                (deletableLocationConnectedAccountMapType =
                                tr.ConnectionType),
                                (deletableLocationConnectedAccountMapId =
                                tr.AccountMapId)
                            "></vs-button>
                                        </div>
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                        <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">
                            Showing {{ showingDataFrom }} to {{ showingDataTo }} of
                            {{ accountMapsForLocation.length|tableRecordNumberFormatter }} entries
                        </p>
                        <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">
                            Showing 1 to {{ totalSearchedData }} of
                            {{ totalSearchedData|tableRecordNumberFormatter }} entries (filtered from
                            {{ accountMapsForLocation.length|tableRecordNumberFormatter }} entries)
                        </p>                        
                    </div>    
                </div>
            </div>
            <!-- Connections END -->

            <div class="connect-account" v-show="step == 3">
                <div class="card-title">
                    <h2>Select Your Review Sources</h2>
                </div>
                <vs-table :data="sources" class="source-table">
                    <template slot="thead">
                        <vs-th width="50">Select</vs-th>
                        <vs-th>Source</vs-th>
                        <vs-th>Listing</vs-th>
                        <vs-th>Action</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td width="50">
                                <vs-checkbox v-model="tr.IsSelected" @change.native="
                      createListing(indextr, tr.ListingSourceId, $event)
                    "></vs-checkbox>
                            </vs-td>
                            <vs-td>
                                <div class="source-list">
                                    <img :src="tr.ListingSourceImageUrl" />
                                    <span>{{ tr.ListingSource }}</span>
                                </div>
                            </vs-td>
                            <vs-td v-if="tr.IsSelected == true">
                                <span v-show="tr.ListingStatus == 'missing'">Listing Not Found</span>
                                <span v-show="
                      tr.PossibleMatches ? tr.PossibleMatches.length > 0 : 0
                    ">
                                    <b>Multiple Listings Found.</b>
                                </span>

                                <span v-show="tr.IsSelected == true && tr.CompanyName" class="blueberry"><b>{{tr.CompanyName}}</b></span><br>
                                <span v-show="tr.IsSelected == true && tr.CompanyName" class="blueberry">{{tr.Address1}}</span>
                                <span v-show="tr.IsSelected == true && tr.CompanyName && tr.City">, {{tr.City}}</span>
                                <span v-show="tr.IsSelected == true && tr.CompanyName && tr.State"> {{tr.State}}</span><br /><span v-show="tr.IsSelected == true && tr.CompanyName && tr.Zip"> {{tr.Zip}}</span>
                            </vs-td>
                            <vs-td v-else></vs-td>
                            <vs-td>
                                <div class="table-action">
                                    <vs-button v-show="tr.IsSelected == true && tr.ListingUrl" title="Edit" type="filled" icon-pack="feather" icon="icon-edit" class="mr-2 float-left bg-actionbutton" @click="editSourceUrl(tr)"></vs-button>
                                    <vs-button v-show="
                        tr.IsSelected == true && tr.ListingStatus == 'missing'
                      " title="Add" @click="
                        (sourceName = tr.ListingSource),
                          (sourceId = tr.ListingSourceId),
                          (addListActive = true)
                      " color="secondary" type="filled" icon-pack="feather" icon="icon-plus" class="float-left"></vs-button>
                                    <vs-button v-show="
                        tr.IsSelected == true && tr.PossibleMatches
                          ? tr.PossibleMatches.length > 0
                          : 0
                      " @click="editSourceUrl(tr)" title="Fix" color="secondary" type="filled" class="float-left small-btn">{{ LabelConstant.buttonLabelFix }}</vs-button>
                                </div>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
                <!--<table>
            <tr>
              <th>Select</th>
              <th>Source</th>
              <th>Listing</th>
              <th>Action</th>
            </tr>
            <tr :key="indextr" v-for="(tr, indextr) in sources">
              <td>
                <vs-checkbox
                  v-model="tr.IsSelected"
                  @change.native="createListing(indextr,tr.ListingSourceId,$event)"
                ></vs-checkbox>
              </td>
              <td>
                <div class="source-list">
                  <img width="50" height="50" :src="tr.ListingSourceImageUrl" />
                  <span>{{tr.ListingSource}}</span>
                </div>
              </td>
              <td>
                <div>{{tr}}</div>
               
              </td>
              <td></td>
            </tr>
          </table>-->
            </div>
            <div class="connect-account" v-show="step == 4">
                <div class="card-title">
                    <h2>Configure Notifications</h2>
                </div>
                <div class="config-notification">
                    <div class="vx-row">
                        <div class="vx-col xl:w-1/3 lg-cus-6 w-full mb-6">
                            <vs-checkbox v-model="emailDailyreport" class="mb-4 ml-0">Email the following people a Daily Report</vs-checkbox>
                            <div v-show="emailDailyreport">
                                <div class="email-box mb-4" v-for="(daily, index) in dailyEmails" :key="index">
                                    <label>Email</label>
                                    <vs-input v-model="daily.demail" />
                                    <vs-button color="success" type="filled" icon-pack="feather" icon="icon-plus" class="ml-2" @click="addEmail('D')"></vs-button>
                                    <vs-button v-if="dailyEmails.length > 1" color="danger" type="filled" icon-pack="feather" icon="icon-minus" class="ml-2" @click="removeEmail('D', index)"></vs-button>
                                </div>
                            </div>
                        </div>
                        <div class="vx-col xl:w-1/3 lg-cus-6 w-full mb-6">
                            <vs-checkbox v-model="emailWeeklyreport" class="mb-4 ml-0">Email the following people a Weekly Report</vs-checkbox>
                            <div v-show="emailWeeklyreport">
                                <div class="email-box mb-4" v-for="(week, index) in weeklyEmails" :key="index">
                                    <label>Email</label>
                                    <vs-input v-model="week.wemail" />
                                    <vs-button color="success" type="filled" icon-pack="feather" icon="icon-plus" class="ml-2" @click="addEmail('W')"></vs-button>
                                    <vs-button v-if="weeklyEmails.length > 1" color="danger" type="filled" icon-pack="feather" icon="icon-minus" class="ml-2" @click="removeEmail('W', index)"></vs-button>
                                </div>
                            </div>
                        </div>
                        <div class="vx-col xl:w-1/3 lg-cus-6 w-full mb-6">
                            <vs-checkbox v-model="emailMonthlyreport" class="mb-4 ml-0">Email the following people a Monthly Report</vs-checkbox>
                            <div v-show="emailMonthlyreport">
                                <div class="email-box mb-4" v-for="(month, index) in monthlyEmails" :key="index">
                                    <label>Email</label>
                                    <vs-input v-model="month.memail" />
                                    <vs-button color="success" type="filled" icon-pack="feather" icon="icon-plus" class="ml-2" @click="addEmail('M')"></vs-button>
                                    <vs-button v-if="monthlyEmails.length > 1" color="danger" type="filled" icon-pack="feather" icon="icon-minus" class="ml-2" @click="removeEmail('M', index)"></vs-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="justify-end flex justify-between w-full pt-4">
            <div class="float-left">
                <vs-button color="primary" type="filled" @click="backStep()" v-if="step != 1">{{ LabelConstant.buttonLabelBack }}</vs-button>
            </div>
            <div class="float-left">
                <vs-button color="grey" type="border" @click="cancelAdd()" class="grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
                <vs-button type="filled" class="ml-4" color="primary" @click="nextStep()">{{ step == 4 ? LabelConstant.buttonLabelFinish : LabelConstant.buttonLabelNext }}</vs-button>
            </div>
        </div>
    </vx-card>

    <!-- Delete Connected Account? (Connections) -->
    <vs-popup class="delete-popup" title="Delete Connected Account?" :active.sync="deleteConnectedAccountPopupActive">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full">
                    <div class="popup-icon">
                        <feather-icon icon="Trash2Icon"></feather-icon>
                    </div>
                    <h4>Delete Connected Account?</h4>
                    <span class="deleted-no">{{ deletableConnectedAccount }}</span>
                </div>
            </div>
        </template>
        <div class="float-right mt-6 mb-8">
            <vs-button color="danger" type="filled" @click="deleteConnectedAccount()">{{ LabelConstant.buttonLabelDelete }}</vs-button>
            <vs-button color="grey" type="border" class="ml-4 grey-btn" @click.prevent="cancelDeleteConnectedAccount()">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <!-- Delete Location Connected Account Map? (Connections) -->
    <vs-popup class="delete-popup" title="" :active.sync="deleteLocationConnectedAccountMapPopupActive">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full">
                    <div class="popup-icon">
                        <feather-icon icon="Trash2Icon"></feather-icon>
                    </div>
                    <h4>Delete Location Connected Account Map?</h4>
                    <span class="deleted-no">{{
              deletableLocationConnectedAccountMapType
            }}</span>
                </div>
            </div>
        </template>
        <div class="float-right mt-6 mb-8">
            <vs-button type="filled" @click="deleteLocationConnectedAccountMap()" color="danger">{{ LabelConstant.buttonLabelDelete }}</vs-button>
            <vs-button color="grey" type="border" class="ml-4 grey-btn" @click.prevent="cancelDeleteLocationConnectedAccountMap()">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <!-- Profile selection (Connections) -->
    <vs-popup :title="profileSelectionPopupTitle" :active.sync="selectProfileActive">
        <template>
            <div class="vx-row">
                <div class="vx-col w-full mb-8 access-page">
                    <h4 class="md:mt-0 mt-3">Select Profile</h4>
                    <vs-select class="selectExample" ref="selectedProfile" label-placeholder="Autocomplete" autocomplete v-model="connectedAccountId" @change="listConnectedAccountAvailableProfiles()">
                        <vs-select-item :key="index" :value="item.Id" :text="item.ConnectedAccountName" v-for="(item, index) in updateLocationPopupProfileList" />
                    </vs-select>
                </div>
                <div class="vx-col w-full access-page">
                    <h4>{{pageOwnOrAccessToTitle}}</h4>
                    <div class="flex w-full flex-wrap justify-between items-center">
                        <vs-table ref="tablePages" search :data="listConnectedAccountAvailableProfilesData" :max-items="perPage" pagination>
                            <template slot-scope="{ data }">
                                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                    <vs-td width="50">
                                        <vs-radio v-model="profileId" :vs-value="tr.ProfileId"></vs-radio>
                                    </vs-td>
                                    <vs-td>
                                        <p>{{ tr.ProfileName }}</p>
                                        <span>{{ tr.ProfileAddress }}</span>
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                        <p class="table-data-entries-details mt-4" v-if="
                  !isSearchUpdateLocation &&
                    isTableRecordEntriesDisplayUpdateLocation
                ">
                            Showing {{ showingDataFromUpdateLocation }} to
                            {{ showingDataToUpdateLocation }} of
                            {{ listConnectedAccountAvailableProfilesData.length|tableRecordNumberFormatter }} entries
                        </p>
                        <p class="table-data-entries-details mt-4" v-if="
                  isSearchUpdateLocation &&
                    isTableRecordEntriesDisplayUpdateLocation
                ">
                            Showing 1 to {{ totalSearchedDataUpdateLocation }} of
                            {{ totalSearchedDataUpdateLocation|tableRecordNumberFormatter }} entries (filtered from
                            {{ listConnectedAccountAvailableProfilesData.length|tableRecordNumberFormatter }} entries)
                        </p>
                        <!--Table Select Row-->
                        <div class="table-row-select mt-4 p-0 md:float-right float-none md:w-auto w-full">
                            <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
                                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in recordList" />
                            </vs-select>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="flex justify-end modal-action-buttons w-full float-left mt-6 mb-8">
            <vs-button @click="updateLocationConnectedAccountMap()" color="primary" type="filled" button="submit">{{ LabelConstant.buttonLabelOk }}</vs-button>
            <vs-button @click.prevent="cancelLocationUpdate()" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <!-- Warning message to connect respective account first  (Connections) -->
    <vs-popup class="transaction-popup" :active.sync="accountConnectWarningPopup">
        <div class="transaction-details">
            <div class="popup-icon"><img src="../../assets/images/warning.svg"></div>
            <!-- <h2>Purchase Additional Users</h2> -->
            <p>{{accountConnectWarningPopupTitle}}</p>
            <vs-button @click="accountConnectWarningPopup=false" color="grey" type="border" class="grey-btn"> {{ LabelConstant.buttonLabelOk }} </vs-button>
        </div>
    </vs-popup>

    <!-- Listing Information -->
    <vs-popup class="list-info popup-width" title="Listing Information" :active.sync="listInfoActive">
        <template>
            <div class="vx-row">
                <div class="vx-col w-full">
                    <div class="card-title">
                        <h2>
                            <img :src="
                    sourcePopupData ? sourcePopupData.ListingSourceImageUrl : ''
                  " />
                            {{ sourcePopupData ? sourcePopupData.ListingSource : "" }}
                        </h2>
                        <feather-icon icon="AlertOctagonIcon" class="text-primary"></feather-icon>
                    </div>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col w-full list-main-title">
                    <h4>
                        {{
                sourcePopupData
                  ? sourcePopupData.ListingStatus == "accurate"
                    ? "Accurate Listing"
                    : "Listing Not Found"
                  : ""
              }}
                    </h4>
                    <span v-if="sourcePopupData && sourcePopupData.PossibleMatches">Possible Matchs</span>
                </div>
                <div class="vx-col w-full mb-8" v-if="sourcePopupData && sourcePopupData.PossibleMatches == null">
                    <div class="table-responsive">
                        <table cellspacing="0">
                            <tr>
                                <td>
                                    <b>Company Name:</b>
                                </td>
                                <td>
                                    {{ sourcePopupData ? sourcePopupData.CompanyName : "" }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Address:</b>
                                </td>
                                <td>{{ sourcePopupData ? sourcePopupData.Address1 : "" }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <b>City:</b>
                                </td>
                                <td>{{ sourcePopupData ? sourcePopupData.City : "" }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <b>State:</b>
                                </td>
                                <td>{{ sourcePopupData ? sourcePopupData.State : "" }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Zip:</b>
                                </td>
                                <td>{{ sourcePopupData ? sourcePopupData.Zip : "" }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Phone:</b>
                                </td>
                                <td>
                                    {{ sourcePopupData ? sourcePopupData.PhonePrimary : "" }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Website:</b>
                                </td>
                                <td>{{ sourcePopupData ? sourcePopupData.WebsiteUrl : "" }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Options</b>
                                </td>
                                <td>
                                    Not Correct?
                                    <vs-button color="success" type="border" class="ml-0 mt-2 sm:mt-0 sm:ml-4 small-btn" @click="
                        removeSitePopup(
                            sourcePopupData.ListingSource,
                            sourcePopupData.ListingSourceId,
                            'S'
                        )
                        ">{{ LabelConstant.buttonLabelRemoveSite }}</vs-button>
                                </td>
                            </tr>
                        </table>
                     </div>
                </div>
                <div class="vx-col w-full" v-if="sourcePopupData && sourcePopupData.PossibleMatches">
                    <div class="vx-row">
                        <div class="vx-col lg:w-1/2 w-full mb-8" v-for="(pm, index) in sourcePopupData.PossibleMatches" :key="index">
                            <table cellspacing="0">
                                <tr>
                                    <td>
                                        <b>Company Name:</b>
                                    </td>
                                    <td>{{ pm.CompanyName }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Address:</b>
                                    </td>
                                    <td>{{ pm.Address1 }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>City:</b>
                                    </td>
                                    <td>{{ pm.City }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>State:</b>
                                    </td>
                                    <td>{{ pm.State }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Zip:</b>
                                    </td>
                                    <td>{{ pm.Zip }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Phone:</b>
                                    </td>
                                    <td>{{ pm.PhonePrimary }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Website:</b>
                                    </td>
                                    <td>{{ pm.WebsiteUrl }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Options</b>
                                    </td>
                                    <td>
                                        <vs-button color="success" type="border" class="small-btn" @click="ViewListing(pm.ListingUrl)">{{ LabelConstant.buttonLabelViewListing }}</vs-button>
                                        <vs-button color="success" type="border" class="ml-0 mt-2 sm:mt-0 sm:ml-4 small-btn" @click="
                          markAsMyListing(sourcePopupData.ListingSourceId,pm)
                        ">{{ LabelConstant.buttonLabelMakeThisMylisting }}</vs-button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="vx-col w-full">
                    <div class="vx-col w-full">
                        <span class="mb-2 w-full float-left">
                            <b>Options</b>
                        </span>
                        <div class="enter-url">
                            <vs-input label="Manually Enter URL" v-model="enterurl" />
                            <vs-button type="filled" class="ml-0 sm:ml-4 sm:mt-5 mt-2" color="primary" @click="overideUrlForSource(sourcePopupData.ListingSourceId)">{{ LabelConstant.buttonLabelOverrideURL }}</vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="flex justify-end modal-action-buttons w-full float-left mt-6 mb-8">
            <vs-button @click="listInfoActive = false" color="grey" class="grey-btn" type="border">{{ LabelConstant.buttonLabelClose }}</vs-button>
        </div>
        <!-- Delete Popup -->
        <vs-popup class="delete-popup" :active.sync="deletepopupActive">
            <template>
                <div class="vx-row mb-3">
                    <div class="vx-col w-full">
                        <div class="popup-icon">
                            <feather-icon icon="Trash2Icon"></feather-icon>
                        </div>
                        <h4>Remove Location?</h4>
                        <span class="deleted-no">{{ sourceName }}</span>
                    </div>
                </div>
            </template>
            <div class="float-right mt-6 mb-8">
                <vs-button color="danger" type="filled" @click="deletetype == 'L' ? deleteLocation() : remove()">{{ LabelConstant.buttonLabelDelete }}</vs-button>
                <vs-button @click="deletepopupActive = false" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </vs-popup>
    </vs-popup>
    <!-- Add Listing -->
    <vs-popup class="tag-info" :title="'Add Listing for ' + sourceName" :active.sync="addListActive">
        <template>
            <div class="vx-row">
                <div class="vx-col input-text w-full">
                    <div class="popup-icon">
                        <img src="../../assets/images/addlist.png" />
                    </div>
                    <h4 class="mt-4">Create Listing</h4>
                    <p class="mb-8">
                        We were not able to find a listing from this source that matched
                        your company name or address. Please manually enter a URL to the
                        correct location.
                    </p>
                    <form data-vv-scope="createlisting" @submit.prevent="checkUrlValid()">
                        <vs-input class="w-full" label-placeholder="Enter URL" v-validate="{ required: true, url: { require_protocol: true } }" name="url" v-model="url" />
                        <span class="text-danger text-sm">{{
                errors.first("createlisting.url")
              }}</span>
                        <div class="float-right mt-6 mb-8">
                            <vs-button type="filled" button="submit" class="bg-actionbutton">{{ LabelConstant.buttonLabelCreateListing }}</vs-button>
                            <vs-button @click="addListActive = false" color="secondary" type="filled" class="ml-4">{{ LabelConstant.buttonLabelCancel }}</vs-button>
                        </div>
                    </form>
                </div>
            </div>
        </template>
    </vs-popup>
    <vs-popup class="tag-info" title="Select GMB Company Id" :active.sync="ListofCompanies">
        <template>
            <div class="vx-row">
                <div class="vx-col input-text w-full">
                    <div class="popup-icon">
                        <img src="../../assets/images/addlist.png" />
                    </div>
                    <h4 class="mt-4">Please select account:</h4>
                    <p class="mb-8">
                        Please select the account you would like to associate for
                        gathering reviews.
                    </p>
                    <vs-button @click="ListofCompanies = false" color="secondary" type="filled" class="ml-4">{{ LabelConstant.buttonLabelSelectCompany }}</vs-button>
                </div>
            </div>
        </template>
    </vs-popup>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import vSelect from "vue-select";
import {
    Validator
} from "vee-validate";
const dict = {
    custom: {
        businessName: {
            required: "The business name field is required"
        },
        friendlyNameForLocation: {
            required: "The friendly name for the location field is required"
        },
        address1: {
            required: "The address1 field is required"
        },
        stateName: {
            required: "The state field is required"
        },
        zipCode: {
            required: "The zip code field is required"
        },
        cityName: {
            required: "The city field is required"
        }
    }
};
Validator.localize("en", dict);
export default {
    components: {
        VxCard,
        vSelect
    },
    data() {
        return {
            //List and update action (Your Connected Accounts)
            connectedAccounts: [],
            reauthAccountId: null,
            reauthAccountType: null,

            //Delete Action in table (Your Connected Accounts)
            deleteConnectedAccountPopupActive: false,
            deletableConnectedAccount: null,
            deletableConnectedAccountId: null,

            //(Profile\Page)
            accountMapsForLocation: [],
            selectProfileActive: false,
            listConnectedAccountAvailableProfilesData: [],
            updateLocationPopupProfileList: [],
            // locationName: null,
            connectedAccountId: null,
            conntectedAccountType: null,
            profileId: null,

            accountConnectWarningPopup: false,
            accountConnectWarningPopupTitle: null,

            profileSelectionPopupTitle: null,
            pageOwnOrAccessToTitle: null,

            //Delete Location Connected Account Map (Profile\Page)
            deleteLocationConnectedAccountMapPopupActive: false,
            deletableLocationConnectedAccountMapId: null,
            deletableLocationConnectedAccountMapType: null,

            perPage: 5,
            recordList: [{
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "25",
                    value: 25
                },
                {
                    text: "50",
                    value: 50
                },
                {
                    text: "100",
                    value: 100
                }
            ],

            ListofCompanies: false,
            deletetype: null,
            deletepopupActive: false,
            sourceId: null,
            sourceName: "",
            locationId: null,
            selectSource: [],
            locations: [],
            input1: null,
            emailMonthlyreport: null,
            emailDailyreport: null,
            emailWeeklyreport: null,
            enterurl: null,
            addEditLocation: false,
            url: null,
            step: 1,
            progressStep: 25,
            listInfoActive: false,
            addListActive: false,
            businessName: null,
            address1: null,
            address2: null,
            friendlyNameForLocation: null,
            country: "US",
            countryoptions: [],
            dailyEmails: [{
                demail: null
            }],
            weeklyEmails: [{
                wemail: null
            }],
            monthlyEmails: [{
                memail: null
            }],
            stateName: null,
            cityName: null,
            zipCode: null,
            phone: null,
            category: "Automotive",
            categoryoptions: [],
            /* table */
            // connectedTo: [],
            acconunt: [{
                    status: "Facebook"
                },
                {
                    status: "Google My Business"
                }
            ],
            sources: [],
            reviewsourceselected: [],
            reviewsource: [1, 2],
            sourcePopupData: {},

            isAddLocation: false
        };
    },
    mounted() {
        if (this.$route.query.setLocation) {
            setTimeout(() => {
                var menuitem = document.getElementsByClassName("vs-sidebar-group ");
                menuitem.forEach(element => {
                    var arrow = element.getElementsByClassName(
                        "feather-icon select-none relative feather-grp-header-arrow"
                    );
                    var text = element.getElementsByClassName(
                        "truncate mr-3 select-none"
                    )[0].innerText;
                    var uls = element.getElementsByTagName("ul");
                    if (text == "Reviews") {
                        element.className += " vs-sidebar-group-open";
                        arrow[0].className += " rotate90";
                        uls[0].style.maxHeight = "none";
                    }
                });
            }, 300);
            this.addEditLocation = true;
        }
        this.getCountries();
        this.getCategories();
        var that = this;
        window.addEventListener("focus", callListingsMethods);

        function callListingsMethods() {
            if (that.isEventListner) {
                that.listConnectedAccounts();
                that.listAccountMapsForLocation();
            }
            that.isEventListner = false;
        }
    },
    created() {
        this.getLocationList();
    },
    methods: {
        //Open popup to display warning to connect account (Profile\Page)
        openAccountConnectWarningPopup() {
            if (this.conntectedAccountType == 'Facebook') {
                this.accountConnectWarningPopupTitle = 'You must "Connect" your Facebook account first'
            } else {
                this.accountConnectWarningPopupTitle = 'You must "Connect" your GMB account first'
            }
            this.accountConnectWarningPopup = true
        },
        //Cancel Delete Location Connected Account Map (Profile\Page)
        cancelDeleteLocationConnectedAccountMap() {
            this.deleteLocationConnectedAccountMapPopupActive = false;
            this.deletableLocationConnectedAccountMapId = null;
            this.deletableLocationConnectedAccountMapType = null;
        },
        //Delete Location Connected Account Map (Profile\Page)
        deleteLocationConnectedAccountMap() {
            let deleteLocationConnectedAccountMapParam = this
                .deletableLocationConnectedAccountMapId;
            this.$vs.loading();
            this.axios
                .get(
                    "/ws/MyConnections/DeleteAccountMapForLocation?id=" +
                    deleteLocationConnectedAccountMapParam
                )
                .then(() => {
                    this.cancelDeleteLocationConnectedAccountMap();
                    this.listAccountMapsForLocation();
                    this.$vs.notify({
                        title: "Success",
                        text: "Location Connected Account Map removed successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        //Update location (Profile\Page)
        updateLocationConnectedAccountMap() {
            let ProfileName = null;
            let ProfileAddress = null;
            for (
                let i = 0; i < this.listConnectedAccountAvailableProfilesData.length; i++
            ) {
                if (
                    this.profileId ==
                    this.listConnectedAccountAvailableProfilesData[i].ProfileId
                ) {
                    ProfileName = this.listConnectedAccountAvailableProfilesData[i]
                        .ProfileName;
                    ProfileAddress = this.listConnectedAccountAvailableProfilesData[i]
                        .ProfileAddress;
                }
            }
            let updateLocationConnectedAccountMapParams = {
                LocationId: this.locationId,
                ConnectedAccountId: this.connectedAccountId,
                ConntectedAccountType: this.conntectedAccountType,
                ProfileId: this.profileId,
                ProfileName: ProfileName,
                ProfileAddress: ProfileAddress
            };
            this.$vs.loading();
            this.axios
                .post(
                    "/ws/MyConnections/UpdateLocationConnectedAccountMap",
                    updateLocationConnectedAccountMapParams
                )
                .then(() => {
                    this.cancelLocationUpdate();
                    this.listAccountMapsForLocation();
                    this.$vs.notify({
                        title: "Success",
                        text: "Location updated successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        //Cancel location update action (Profile\Page)
        cancelLocationUpdate() {
            this.selectProfileActive = false;
            this.listConnectedAccountAvailableProfilesData = [];
            this.locationName = null;
            this.connectedAccountId = null;
            this.conntectedAccountType = null;
            this.profileId = null;
            if (this.$refs.selectedProfile) {
                this.$refs.selectedProfile.valuex = "";
            }
            this.$refs.tablePages.searchx = null;
        },
        //Locations you have access to (Profile\Page)
        listConnectedAccountAvailableProfiles() {
            if (this.connectedAccountId) {
                this.$vs.loading();
                this.axios
                    .get(
                        "/ws/MyConnections/ListConnectedAccountAvailableProfiles?id=" +
                        this.connectedAccountId
                    )
                    .then(response => {
                        let data = response.data;
                        this.listConnectedAccountAvailableProfilesData = data;
                        // this.listConnectedAccountAvailableProfilesData = [{
                        //   ProfileName: 'Visual Visitor',
                        //   ProfileAddress: '1080 Holcomb Bridge Road #200 Rowell, 30076',
                        //   ProfileId: '123456',
                        //   ProfileImageUrl: '/assets/image.png'
                        // }]
                        this.$vs.loading.close();
                    })
                    .catch(e => {
                        this.$vs.loading.close();
                        this.showError(e);
                    });
            }
        },
        //Prepate profile list based on account type for update action (Profile\Page)
        prepareProfileList() {
            if (this.conntectedAccountType == 'Facebook') {
                this.profileSelectionPopupTitle = 'Select the Facebook page for gathering reviews'
                this.pageOwnOrAccessToTitle = 'Facebook pages you have access to'
            } else {
                this.profileSelectionPopupTitle = 'Select the GMB location for gathering reviews'
                this.pageOwnOrAccessToTitle = 'GMB locations you have access to'
            }
            this.updateLocationPopupProfileList = [];
            for (let i = 0; i < this.connectedAccounts.length; i++) {
                if (
                    this.connectedAccounts[i].ConnectedAccountType ==
                    this.conntectedAccountType
                ) {
                    this.updateLocationPopupProfileList.push(this.connectedAccounts[i]);
                }
            }

            if(this.updateLocationPopupProfileList.length > 0){
                this.selectProfileActive = true
                this.connectedAccountId = this.updateLocationPopupProfileList[0].Id;
            }else{
                this.openAccountConnectWarningPopup()
            }
            // this.listConnectedAccountAvailableProfiles()
        },
        //Cancel Deletion (Your Connected Accounts)
        cancelDeleteConnectedAccount() {
            this.deleteConnectedAccountPopupActive = false;
            this.deletableConnectedAccount = null;
            this.deletableConnectedAccountId = null;
        },
        //Delete Action in table (Your Connected Accounts)
        deleteConnectedAccount() {
            this.$vs.loading();
            this.axios
                .get(
                    "/ws/MyConnections/DeleteConnectedAccount?id=" +
                    this.deletableConnectedAccountId
                )
                .then(() => {
                    this.cancelDeleteConnectedAccount();
                    this.listConnectedAccounts();
                    this.listAccountMapsForLocation();
                    this.$vs.notify({
                        title: "Success",
                        text: "Connected Account removed successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        //Re-auth Action in table (Your Connected Accounts)
        reauthConnectedAccount() {
            this.$vs.loading();
            this.axios
                .get(
                    "/ws/MyConnections/ReauthConnectedAccount?id=" + this.reauthAccountId
                )
                .then(response => {
                    let data = response.data;
                    let requestId = data;
                    this.isEventListner = true;
                    if (this.reauthAccountType == "Facebook") {
                        window
                            .open(
                                requestId,
                                +"Facebook Organic Authorization",
                                "toolbar=0,scrollbars=1,location=0,status=0,menubar=0,resizable=1,width=650, height=550,left = 300,top=100"
                            )
                            .focus();
                    } else {
                        window
                            .open(
                                requestId,
                                +"Google My Business Authorization",
                                "toolbar=0,scrollbars=1,location=0,status=0,menubar=0,resizable=1,width=650, height=550,left = 300,top=100"
                            )
                            .focus();
                    }
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Add GMB Profile (Your Connected Accounts)
        addGMBConnectedAccount() {
            this.$vs.loading();
            this.axios
                .get("/ws/MyConnections/AddGMBConnectedAccount")
                .then(response => {
                    let data = response.data;
                    let requestId = data;
                    this.isEventListner = true;
                    window
                        .open(
                            requestId,
                            "Google My Business Authorization",
                            "toolbar=0,scrollbars=1,location=0,status=0,menubar=0,resizable=1,width=650, height=550,left = 300,top=100"
                        )
                        .focus();
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Add Facebook Profile (Your Connected Accounts)
        addFacebookConnectedAccount() {
            this.$vs.loading();
            this.axios
                .get("/ws/MyConnections/AddFacebookConnectedAccount")
                .then(response => {
                    let data = response.data;
                    let requestId = data;
                    this.isEventListner = true;
                    window
                        .open(
                            requestId,
                            +"Facebook Organic Authorization",
                            "toolbar=0,scrollbars=1,location=0,status=0,menubar=0,resizable=1,width=650, height=550,left = 300,top=100"
                        )
                        .focus();
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Profile\Page
        listAccountMapsForLocation() {
            this.$vs.loading();
            this.axios
                .get(
                    "/ws/MyConnections/ListAccountMapsForLocation?id=" + this.locationId
                )
                .then(response => {
                    let data = response.data;
                    this.accountMapsForLocation = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Your connected accounts (Your Connected Accounts)
        listConnectedAccounts() {
            this.$vs.loading();
            this.axios
                .get("/ws/MyConnections/ListConnectedAccounts")
                .then(response => {
                    let data = response.data;

                    this.connectedAccounts = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async overideUrlForSource(sid) {
            let input = {
                ListingSourceId: sid,
                LocationId: this.locationId,
                URL: this.enterurl
            };
            await this.axios
                .post("/ws/ReviewsGetStarted/SetURLForListing", input)
                .then(() => {
                    this.sourceId = null;
                    this.enterurl = null;
                    this.listInfoActive = false;
                    this.GetSourceList();
                    setTimeout(() => {
                        this.errors.clear();
                    }, 50);
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // add listing when not found
        async createListing(index, id, $event) {
            this.$vs.loading();
            let that = this;
            let check = $event.target.value;
            if (check === "true") {
                let input = {
                    LocationId: this.locationId ? this.locationId : 0,
                    ListingSourceId: id
                };
                await this.axios
                    .post("/ws/ReviewsGetStarted/CreateListing", input)
                    .then(response => {
                        let data = response.data;
                        this.sources.forEach((element, indexnew) => {
                            if (element.ListingSourceId === data.ListingSourceId) {
                                that.$set(this.sources, indexnew, data);
                            }
                        });
                        this.$vs.loading.close();
                    })
                    .catch(e => {
                        this.$vs.loading.close();
                        this.showError(e);
                    });
            } else {
                let input = {
                    LocationId: this.locationId ? this.locationId : 0,
                    ListingSourceId: id
                };
                await this.axios
                    .post(
                        "/ws/ReviewsGetStarted/RemoveListingBySourceIdAndLocationId",
                        input
                    )
                    .then(() => {
                        this.$vs.loading.close();
                    })
                    .catch(e => {
                        this.$vs.loading.close();
                        this.showError(e);
                    });
            }
        },
        // edit source url
        editSourceUrl(source) {
            this.listInfoActive = true;
            this.sourcePopupData = source;
        },
        // when click on add email
        addEmail(type) {
            if (type === "D") {
                this.dailyEmails.push({
                    demail: null
                });
            } else if (type === "W") {
                this.weeklyEmails.push({
                    wemail: null
                });
            } else {
                this.monthlyEmails.push({
                    memail: null
                });
            }
        },
        // remove email
        removeEmail(type, index) {
            if (type === "D") {
                this.dailyEmails.splice(index, 1);
            } else if (type === "W") {
                this.weeklyEmails.splice(index, 1);
            } else {
                this.monthlyEmails.splice(index, 1);
            }
        },
        // when click on next button
        nextStep() {
            if (this.step == 1) {
                this.$validator.validateAll("basicInformation").then(async result => {
                    if (result) {
                        this.updateLocationData();
                        this.nextSuccess();
                    }
                });
            } else if (this.step == 2) {
                this.GetSourceList();
                this.nextSuccess();
            } else if (this.step == 3) {
                this.getNotificationSetting();
                this.nextSuccess();
            } else {
                this.updateNoticationSetting();
            }
        },
        // when validation success
        nextSuccess() {
            let step = this.step;
            this.step = step + 1;
            let pg = this.progressStep;
            this.progressStep = pg + 25;
        },
        // go back
        backStep() {
            let step = this.step;
            this.step = step - 1;
            let pg = this.progressStep;
            this.progressStep = pg - 25;
        },
        //get location list
        async getLocationList() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ReviewsGetStarted/GetLocationList")
                .then(response => {
                    let data = response.data;
                    if (data.length <= 0) {
                        this.addEditLocation = true;
                    }
                    this.isAddLocation =
                        data.length < this.$store.state.maxReviewLocations ? false : true;
                    this.locations = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // add location api 
        addLocation() {
            this.addEditLocation = true;
            this.step = 1;
            this.locationId = null;
            this.getCategories();
            this.getCountries();
        },
        // get categories list
        async getCategories() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ReviewsGetStarted/ListBusinessCategories")
                .then(response => {
                    let data = response.data;
                    this.categoryoptions = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get countries list
        async getCountries() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ReviewsGetStarted/ListAvailableCounties")
                .then(response => {
                    let data = response.data;
                    this.countryoptions = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get source list
        async GetSourceList() {
            this.$vs.loading();
            await this.axios
                .get("ws/ReviewsGetStarted/GetSourceList", {
                    params: {
                        id: this.locationId
                    }
                })
                .then(response => {
                    let data = response.data;
                    this.sources = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // update location data
        async updateLocationData() {
            this.$vs.loading();
            let input = {
                LocationId: this.locationId ? this.locationId : 0,
                LocationName: this.friendlyNameForLocation,
                BusinessName: this.businessName,
                Address1: this.address1,
                Address2: this.address2,
                City: this.cityName,
                State: this.stateName,
                Zip: this.zipCode,
                Country: this.country,
                Phone: this.phone,
                Category: this.category,
                FacebookLinked: false,
                GMBLinked: false,
                FacebookLinkSuccessful: false,
                GMBLinkSuccessful: false,
                FacebookCompanyId: "",
                GMBCompanyId: ""
            };
            await this.axios
                .post("/ws/ReviewsGetStarted/UpdateLocationDetail", input)
                .then(response => {
                    let data = response.data;
                    this.locationId = data.LocationId;
                    this.businessName = data.BusinessName;
                    this.friendlyNameForLocation = data.LocationName;
                    this.address1 = data.Address1;
                    this.address2 = data.Address2;
                    this.cityName = data.City;
                    this.stateName = data.State;
                    this.zipCode = data.Zip;
                    this.country = data.Country;
                    this.phone = data.Phone;
                    this.category = data.Category;
                    // this.getAccountsConnected();
                    this.listAccountMapsForLocation();
                    this.listConnectedAccounts();
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        // async getAccountsConnected() {
        //   this.$vs.loading();
        //   await this.axios
        //     .get("/ws/ReviewsGetStarted/GetConnectedAccounts?id=" + this.locationId)
        //     .then(response => {
        //       let data = response.data;
        //       this.connectedTo = data;
        //       this.$vs.loading.close();
        //     })
        //     .catch(e => {
        //       this.$vs.loading.close();
        //       this.showError(e);
        //     });
        // },

        // edit location 
        editLocation(location) {
            //    this.$vs.loading();
            this.locationId = location.LocationId;
            this.businessName = location.BusinessName;
            this.friendlyNameForLocation = location.LocationName;
            this.address1 = location.Address1;
            this.address2 = location.Address2;
            this.cityName = location.City;
            this.stateName = location.State;
            this.zipCode = location.Zip;
            this.country = location.Country;
            this.phone = location.Phone;
            this.category = location.Category;
            this.addEditLocation = true;
            this.step = 1;
            this.getCategories();
            this.getCountries();
            // this.$vs.loading.close();
        },
        async getNotificationSetting() {
            //GetLocationNotificationSettings
            this.$vs.loading();
            await this.axios
                .get(
                    "/ws/ReviewsGetStarted/GetLocationNotificationSettings?id=" +
                    this.locationId
                )
                .then(response => {
                    let data = response.data;
                    this.emailMonthlyreport = data.EnableMonthlyRollup;
                    this.emailDailyreport = data.EnableDailyRollup;
                    this.emailWeeklyreport = data.EnableWeeklyRollup;

                    if (data.DailyRollupEmails.length > 0) {
                        this.dailyEmails = [];
                        data.DailyRollupEmails.forEach(element => {
                            this.dailyEmails.push({
                                demail: element
                            });
                        });
                    }
                    if (data.WeeklyRollupEmails.length > 0) {
                        this.weeklyEmails = [];
                        data.WeeklyRollupEmails.forEach(element => {
                            this.weeklyEmails.push({
                                wemail: element
                            });
                        });
                    }
                    if (data.MonthlyRollupEmails.length > 0) {
                        this.monthlyEmails = [];

                        data.MonthlyRollupEmails.forEach(element => {
                            this.monthlyEmails.push({
                                memail: element
                            });
                        });
                    }
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // update notifaction setting 
        async updateNoticationSetting() {
            this.$vs.loading();
            let daily = [];
            let weekly = [];
            let monthly = [];
            this.dailyEmails.forEach(element => {
                if (element) {
                    daily.push(element.demail);
                }
            });
            this.weeklyEmails.forEach(element => {
                if (element) {
                    weekly.push(element.wemail);
                }
            });

            this.monthlyEmails.forEach(element => {
                if (element) {
                    monthly.push(element.memail);
                }
            });
            let input = {
                LocationId: this.locationId,
                EnableDailyRollup: this.emailDailyreport,
                EnableWeeklyRollup: this.emailWeeklyreport,
                EnableMonthlyRollup: this.emailMonthlyreport,
                DailyRollupEmails: daily,
                WeeklyRollupEmails: weekly,
                MonthlyRollupEmails: monthly
            };
            await this.axios
                .post("/ws/ReviewsGetStarted/UpdateLocationNotificationSettings", input)
                .then(() => {
                    // let data = response.data;
                    this.addEditLocation = false;
                    this.locationId = null;
                    this.stateName = null;
                    this.cityName = null;
                    this.zipCode = null;
                    this.phone = null;
                    this.category = "Automotive";
                    this.categoryoptions = [];
                    this.businessName = null;
                    this.address1 = null;
                    this.address2 = null;
                    this.country = "US";
                    this.friendlyNameForLocation = null;
                    setTimeout(() => {
                        this.errors.clear();
                    }, 50);
                    this.getLocationList();
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // check url valid or not 
        checkUrlValid() {
            this.$validator.validateAll("createlisting").then(result => {
                if (result) {
                    this.createUrlForSource();
                }
            });
        },
        // create url for source
        async createUrlForSource() {
            let input = {
                ListingSourceId: this.sourceId,
                LocationId: this.locationId,
                URL: this.url
            };
            await this.axios
                .post("/ws/ReviewsGetStarted/SetURLForListing", input)
                .then(() => {
                    this.sourceId = null;
                    this.url = null;
                    this.addListActive = false;
                    this.GetSourceList();
                    setTimeout(() => {
                        this.errors.clear();
                    }, 50);

                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // mark as my listing api 
        async markAsMyListing(sid, possiblematch) {
            let input = {
                ListingSourceId: sid,
                LocationId: this.locationId,
                PossibleMatch: possiblematch
            };
            await this.axios
                .post("/ws/ReviewsGetStarted/MarkThisAsMyListing", input)
                .then(() => {
                    this.sourceId = null;
                    this.listInfoActive = false;
                    this.GetSourceList();
                    setTimeout(() => {
                        this.errors.clear();
                    }, 50);

                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // view listing method
        ViewListing(url) {
            window.open(url, "_blank");
        },
        // when we click on cancel 
        cancelAdd() {
            this.addEditLocation = false;
            this.locationId = null;
            this.stateName = null;
            this.cityName = null;
            this.zipCode = null;
            this.phone = null;
            this.category = "Automotive";
            this.categoryoptions = [];
            this.businessName = null;
            this.address1 = null;
            this.address2 = null;
            this.country = "US";
            this.friendlyNameForLocation = null;
            setTimeout(() => {
                this.errors.clear();
            }, 50);
            this.getLocationList();
        },
        // remove site popup
        removeSitePopup(name, sid, type) {
            this.deletetype = type;
            this.sourceId = sid;
            this.deletepopupActive = true;
            this.sourceName = name;
        },
        // remove source
        async remove() {
            let input = {
                ListingSourceId: this.sourceId,
                LocationId: this.locationId
            };
            await this.axios
                .post(
                    "/ws/ReviewsGetStarted/MarkListingAsMissingBySourceIdAndLocationId",
                    input
                )
                .then(() => {
                    this.deletepopupActive = false;
                    this.sourceId = null;
                    this.listInfoActive = false;
                    this.GetSourceList();
                    setTimeout(() => {
                        this.errors.clear();
                    }, 50);

                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // delete location from listing confirmation
        deletelocation(id, name, type) {
            this.deletetype = type;
            this.sourceName = name;
            this.deletepopupActive = true;
            this.locationId = id;
        },
        // delete location
        async deleteLocation() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ReviewsGetStarted/DeleteLocation?id=" + this.locationId)
                .then(() => {
                    this.$vs.loading.close();
                    this.locationId = null;
                    this.deletepopupActive = false;
                    this.sourceName = null;
                    this.getLocationList();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        }
    },
    computed: {
        /**
         * Map Your Profile pages to your locations
         * Showing Entries details
         * isTableRecordEntriesDisplay()
         * isSearch()
         * totalSearchedData()
         * showingDataFrom()
         * showingDataTo()
         *  */
        isTableRecordEntriesDisplay() {
            if (this.accountMapsForLocation.length > 0) {
                if (this.$refs.tableMapProfileLocation.datax.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        isSearch: function () {
            if (this.accountMapsForLocation.length > 0) {
                if (
                    this.$refs.tableMapProfileLocation.searchx == null ||
                    this.$refs.tableMapProfileLocation.searchx == ""
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        totalSearchedData: function () {
            if (this.accountMapsForLocation.length > 0) {
                return this.$refs.tableMapProfileLocation.datax.length;
            }
        },
        showingDataFrom: function () {
            if (this.accountMapsForLocation.length > 0) {
                return (
                    (this.$refs.tableMapProfileLocation.currentx - 1) * this.perPage + 1
                );
            }
        },
        showingDataTo: function () {
            if (this.accountMapsForLocation.length > 0) {
                let totalPages = this.$refs.tableMapProfileLocation.getTotalPages;
                if (totalPages == this.$refs.tableMapProfileLocation.currentx) {
                    return (
                        (this.$refs.tableMapProfileLocation.currentx - 1) * this.perPage +
                        this.$refs.tableMapProfileLocation.datax.length
                    );
                } else {
                    return this.$refs.tableMapProfileLocation.currentx * this.perPage;
                }
            }
        },
        /**
         * Update Profile
         * Showing Entries details
         * isTableRecordEntriesDisplayUpdateLocation()
         * isSearchUpdateLocation()
         * totalSearchedDataUpdateLocation()
         * showingDataFromUpdateLocation()
         * showingDataToUpdateLocation()
         *  */
        isTableRecordEntriesDisplayUpdateLocation() {
            if (this.listConnectedAccountAvailableProfilesData.length > 0) {
                if (this.$refs.tablePages.datax.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        isSearchUpdateLocation: function () {
            if (this.listConnectedAccountAvailableProfilesData.length > 0) {
                if (
                    this.$refs.tablePages.searchx == null ||
                    this.$refs.tablePages.searchx == ""
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        totalSearchedDataUpdateLocation: function () {
            if (this.listConnectedAccountAvailableProfilesData.length > 0) {
                return this.$refs.tablePages.datax.length;
            }
        },
        showingDataFromUpdateLocation: function () {
            if (this.listConnectedAccountAvailableProfilesData.length > 0) {
                return (this.$refs.tablePages.currentx - 1) * this.perPage + 1;
            }
        },
        showingDataToUpdateLocation: function () {
            if (this.listConnectedAccountAvailableProfilesData.length > 0) {
                let totalPages = this.$refs.tablePages.getTotalPages;
                if (totalPages == this.$refs.tablePages.currentx) {
                    return (
                        (this.$refs.tablePages.currentx - 1) * this.perPage +
                        this.$refs.tablePages.datax.length
                    );
                } else {
                    return this.$refs.tablePages.currentx * this.perPage;
                }
            }
        }
    }
};
</script>
